@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Manrope", sans-serif;
  }

  body {
    color: #111827;
    font-weight: normal;
    font-family: "Manrope", sans-serif;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Manrope", sans-serif;
}

.sidebar-inners {
  height: calc(100vh - 250px);
  overflow: hidden scroll;
}

.sidebar-inner {
  height: calc(100vh - 250px);
  overflow: hidden;
}

/* Show scrollbar only when content is scrollable */
.sidebar-inner:hover {
  overflow: auto; /* Show the scrollbar on hover */
}

/* Width */
.sidebar-inner::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
.sidebar-inner::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff;
  border-radius: 10px;
}

/* Handle */
.sidebar-inner::-webkit-scrollbar-thumb {
  background-color: #dee2e6; /* 121212 */
  border-radius: 10px;
}

/* Handle on hover */
.sidebar-inner::-webkit-scrollbar-thumb:hover {
  background-color: #b0acae;
}

.active-sidebar-menu-option {
  background-color: #00535c;
  border-radius: 8px;
}

.in-active-sidebar-menu-option:hover {
  background-color: #ffffff1a;
  border-radius: 8px;
}

.image-viewer-wrapper .PhotoView__PhotoWrap .PhotoView__PhotoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: none !important;
  will-change: unset !important;
}

.image-viewer-wrapper .PhotoView-Slider__BannerWrap {
  height: 85px;
  top: 42px;
  max-width: calc(100% - 80px);
  left: 40px;
  background-color: transparent;
}

.image-viewer-wrapper
  .PhotoView-Slider__BannerWrap
  .PhotoView-Slider__BannerRight {
  width: 100%;
}

.image-viewer-wrapper .PhotoView-Slider__ArrowLeft svg,
.image-viewer-wrapper .PhotoView-Slider__ArrowRight svg {
  fill: transparent;
  background: unset;
  padding: 0;
  width: fit-content;
  height: fit-content;
}

.image-viewer-wrapper .PhotoView-Slider__ArrowLeft,
.image-viewer-wrapper .PhotoView-Slider__ArrowRight {
  width: fit-content;
}

.image-viewer-wrapper .PhotoView-Slider__ArrowLeft,
.image-viewer-wrapper .PhotoView-Slider__ArrowRight {
  display: none;
}
.image-viewer-wrapper .PhotoView-Slider__BannerWrap .PhotoView-Slider__Counter,
.image-viewer-wrapper
  .PhotoView-Slider__BannerWrap
  .PhotoView-Slider__BannerRight
  .PhotoView-Slider__toolbarIcon {
  display: none;
}
