.Toastify__toast-container {
  width: 421px;
  padding: 0;
}

.Toastify__toast--default {
  padding: 0;
  border: none;
  min-height: unset;
  background-color: transparent !important;
  border-radius: 12px;
}

.Toastify__toast-body {
  margin: 0;
  padding: 0;
  width: 100%;
  flex: unset;
}

.Toastify__close-button--default {
  display: none;
}

.Toastify__toast--default .Toastify__progress-bar {
  opacity: 0 !important;
}

.custom-toast-success-wrapper {
  background-color: #009642;
}

.custom-toast-warning-wrapper {
  background-color: #f49d1b;
}

.custom-toast-error-wrapper {
  background-color: #ef4444;
}

.custom-toast-info-wrapper {
  background-color: #006770;
}

.custom-toast-wrapper {
  width: 100%;
  min-height: 62px;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.custom-toast-content-wrapper {
  display: flex;
  align-items: flex-start;
}

.custom-toast-icon-wrapper {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.custom-toast-icon-wrapper img {
  width: 100%;
}

.custom-toast-content {
  width: 325px;
}

.custom-toast-title {
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1px;
}

.custom-toast-message {
  color: #ffffff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1px;
  margin-top: 5px;
}

.custom-toast-loader {
  width: 24px;
  height: 24px;
}

.custom-toast-loader img {
  width: 100%;
}

/* Keyframes for continuous rotation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.custom-toast-spinner {
  animation: spin 2s linear infinite; /* 2 seconds for full rotation */
  transform-origin: center; /* Rotate around the center of the SVG */
}

@media screen and (max-width: 480px) {
  .Toastify__toast--default {
    margin-bottom: 1rem;
  }

  .Toastify__toast-container {
    width: 100%;
    max-width: 90%;
    left: 5%;
  }

  .custom-toast-content-wrapper {
    width: 88%;
  }

  .custom-toast-content {
    width: calc(100% - 34px);
  }
}
